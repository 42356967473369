exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-announcements-js": () => import("./../../../src/pages/announcements.js" /* webpackChunkName: "component---src-pages-announcements-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-datacebo-inc-mutual-confidentiality-agreement-index-js": () => import("./../../../src/pages/datacebo-inc-mutual-confidentiality-agreement/index.js" /* webpackChunkName: "component---src-pages-datacebo-inc-mutual-confidentiality-agreement-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-license-faqs-js": () => import("./../../../src/pages/license-faqs.js" /* webpackChunkName: "component---src-pages-license-faqs-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-pricing-enterprise-contact-our-team-js": () => import("./../../../src/pages/pricing/enterprise/contact-our-team.js" /* webpackChunkName: "component---src-pages-pricing-enterprise-contact-our-team-js" */),
  "component---src-pages-pricing-enterprise-contact-sales-js": () => import("./../../../src/pages/pricing/enterprise/contact-sales.js" /* webpackChunkName: "component---src-pages-pricing-enterprise-contact-sales-js" */),
  "component---src-pages-pricing-enterprise-index-js": () => import("./../../../src/pages/pricing/enterprise/index.js" /* webpackChunkName: "component---src-pages-pricing-enterprise-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-sdv-enterprise-developer-license-agreement-03-06-2024-js": () => import("./../../../src/pages/sdv-enterprise-developer-license-agreement/03-06-2024.js" /* webpackChunkName: "component---src-pages-sdv-enterprise-developer-license-agreement-03-06-2024-js" */),
  "component---src-pages-sdv-enterprise-developer-license-agreement-04-01-2024-js": () => import("./../../../src/pages/sdv-enterprise-developer-license-agreement/04-01-2024.js" /* webpackChunkName: "component---src-pages-sdv-enterprise-developer-license-agreement-04-01-2024-js" */),
  "component---src-pages-sdv-enterprise-developer-license-agreement-05-16-2024-js": () => import("./../../../src/pages/sdv-enterprise-developer-license-agreement/05-16-2024.js" /* webpackChunkName: "component---src-pages-sdv-enterprise-developer-license-agreement-05-16-2024-js" */),
  "component---src-pages-sdv-enterprise-developer-license-agreement-index-js": () => import("./../../../src/pages/sdv-enterprise-developer-license-agreement/index.js" /* webpackChunkName: "component---src-pages-sdv-enterprise-developer-license-agreement-index-js" */),
  "component---src-pages-sdv-enterprise-js": () => import("./../../../src/pages/sdv-enterprise.js" /* webpackChunkName: "component---src-pages-sdv-enterprise-js" */),
  "component---src-pages-sdv-production-license-agreement-01-15-2024-js": () => import("./../../../src/pages/sdv-production-license-agreement/01-15-2024.js" /* webpackChunkName: "component---src-pages-sdv-production-license-agreement-01-15-2024-js" */),
  "component---src-pages-sdv-production-license-agreement-03-06-2024-js": () => import("./../../../src/pages/sdv-production-license-agreement/03-06-2024.js" /* webpackChunkName: "component---src-pages-sdv-production-license-agreement-03-06-2024-js" */),
  "component---src-pages-sdv-production-license-agreement-04-08-2024-js": () => import("./../../../src/pages/sdv-production-license-agreement/04-08-2024.js" /* webpackChunkName: "component---src-pages-sdv-production-license-agreement-04-08-2024-js" */),
  "component---src-pages-sdv-production-license-agreement-05-14-2024-js": () => import("./../../../src/pages/sdv-production-license-agreement/05-14-2024.js" /* webpackChunkName: "component---src-pages-sdv-production-license-agreement-05-14-2024-js" */),
  "component---src-pages-sdv-production-license-agreement-07-10-2024-js": () => import("./../../../src/pages/sdv-production-license-agreement/07-10-2024.js" /* webpackChunkName: "component---src-pages-sdv-production-license-agreement-07-10-2024-js" */),
  "component---src-pages-sdv-production-license-agreement-index-js": () => import("./../../../src/pages/sdv-production-license-agreement/index.js" /* webpackChunkName: "component---src-pages-sdv-production-license-agreement-index-js" */),
  "component---src-pages-sdventerprise-js": () => import("./../../../src/pages/sdventerprise.js" /* webpackChunkName: "component---src-pages-sdventerprise-js" */),
  "component---src-pages-support-contact-our-team-js": () => import("./../../../src/pages/support/contact-our-team.js" /* webpackChunkName: "component---src-pages-support-contact-our-team-js" */),
  "component---src-pages-support-contact-sales-js": () => import("./../../../src/pages/support/contact-sales.js" /* webpackChunkName: "component---src-pages-support-contact-sales-js" */),
  "component---src-pages-support-get-in-touch-js": () => import("./../../../src/pages/support/get-in-touch.js" /* webpackChunkName: "component---src-pages-support-get-in-touch-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-announcement-js": () => import("./../../../src/templates/announcement.js" /* webpackChunkName: "component---src-templates-announcement-js" */),
  "component---src-templates-position-js": () => import("./../../../src/templates/position.js" /* webpackChunkName: "component---src-templates-position-js" */)
}

